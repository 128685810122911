$font-family: "Roboto", sans-serif;

$toolbar-height: 64px;
$footer-height: 64px;
$horizontal-menu-height: $toolbar-height;

$sidenav-width: 232px;
$sidenav-user-block-height: 156px;
$mini-sidenav-user-block-height: 70px;

$flex-distance: 12px;
$inner-sidenav-content-padding: 12px;

//Login, Register, Errors box
$box-height: 186px;
$box-position: 34px;
$register-box-height: 300px;

$white-color: #ffffff;
$white-smoke: #f2f2f2;
$nero: #191919;
$dim-grey: #666666;
$sunset-orange: #ff4d4f;

// indigo-pink, deeppurple-amber, purple-green and pink-bluegrey.
@import "~@angular/material/theming";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-primary: (
  main: #125fab,
  lighter: #b8cfe6,
  darker: #0a4391,
  200: #125fab,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$app-primary: mat-palette($mat-primary, main, lighter, darker);

// stylelint-disable-next-line value-keyword-case
$app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Material design icons font path
$material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";

// Export theme colors
$primary: mat-color($app-primary);
$accent: mat-color($app-accent);
$warn: mat-color($app-warn);
