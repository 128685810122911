@font-face {
  font-family: TTCommons-Regular;
  src: url(https://templates.tvstartup.com/broadcaster/wp-content/themes/tvstartupdemo/fonts/TTCommons-Regular.ttf)
    format("ttf");
}
@font-face {
  font-family: TTCommons-Medium;
  src: url(https://templates.tvstartup.com/broadcaster/wp-content/themes/tvstartupdemo/fonts/TTCommons-Medium.ttf)
    format("ttf");
}
