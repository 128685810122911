@import "theme-reset";
@import "variables";
.app {
  &.indigo-light {
    @include theme-reset($app-theme);
  }
}
.zoom-container {
  .mat-accent {
    .mat-slider-track-fill {
      background-color: #dedede !important;
    }
  }

  .mat-slider-thumb-label {
    background-color: #dedede !important;
  }

  .mat-slider-thumb {
    background-color: #dedede !important;
  }
}
