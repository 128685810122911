$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin mobile {
  @media (max-width: #{$mobile -width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin menu-level-padding($direction) {
  $elem: "";
  @for $i from 2 through 10 {
    $elem: if($i == 2, ".sub-menu", selector-nest($elem, ".sub-menu"));
    #{$elem + " .mat-button"} {
      @if $direction == "rtl" {
        padding-right: 20px * $i;
      } @else {
        padding-left: 20px * $i;
      }
    }
  }
}
@mixin truncate-height($font-size, $line-height, $lines-to-show) {
  font-size: $font-size;
  display: block;
  display: -webkit-box;
  line-height: $line-height;
  height: $font-size * $line-height * $lines-to-show;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin video-duration() {
  position: absolute;
  top: 10px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 0 6px;
  font-size: 12px;
  color: $white-color;
}
@mixin video-thumb() {
  width: 100%;
  aspect-ratio: 16/9;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@mixin video-action() {
  height: 40px;
  padding: 5px;
  .video-name {
    max-width: calc(100% - 32px);
    @include truncate();
  }
  .mat-icon {
    cursor: pointer;
  }
}

@mixin audio-action() {
  height: 40px;
  padding: 5px;
  .audio-name {
    max-width: calc(100% - 32px);
    @include truncate();
  }
  .mat-icon {
    cursor: pointer;
  }
}
