/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Cookie&display=swap");
@import url("https://cdn.quilljs.com/1.2.2/quill.snow.css");
@import url("https://cdn.quilljs.com/1.2.2/quill.bubble.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

@import "./theme/base";
@import "./theme/theme";
@import "./theme/libs-override";
@import "./theme/rtl";
@import "./theme/variables";
@import "./theme/fonts";
// 3rd party libraries
@import "~material-design-icons-iconfont/src/material-design-icons";

// Angular Material custom theme
// The mixins below must be included once so we separated them from the variables
@import "~@angular/material/theming";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);

@import "~angular-notifier/styles";

@import "~ng-pick-datetime/assets/style/picker.min.css";

@import "../node_modules/angular-calendar/css/angular-calendar.css";

@import "~video.js/dist/video-js.css";

@import "~flag-icon-css/css/flag-icon.css";

@import "~@circlon/angular-tree-component/css/angular-tree-component.css";

.app-full-bleed-dialog .mat-dialog-container {
  padding: 5px;
}

.mat-sort-header-container {
  justify-content: center;
}
