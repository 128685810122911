@import "mixins";

.app[dir="rtl"] {
  .sidenav-toggle {
    margin-right: 0;
    margin-left: 10px;
  }
  .options-icon {
    left: -4px;
  }
  .options {
    .mat-slider-horizontal {
      overflow: hidden; //for solve chrome bug
    }
  }
  .pin {
    right: auto;
    left: 2px;
  }
  .menu-icon {
    margin-left: 12px;
  }
  .menu-expand-icon {
    right: auto;
    left: 10px;
  }
  .sub-menu {
    @include menu-level-padding("rtl");
    .mat-button {
      padding-right: 20px;
    }
  }
  .back-to-top {
    right: auto;
    left: 20px;
  }
  .messages img {
    margin-right: 0;
    margin-left: 8px;
  }
  .messages {
    img,
    .row-1 {
      margin-right: 0;
      margin-left: 8px;
    }
    span.text {
      text-align: right;
    }
  }
  .flags img {
    margin-right: 0;
    margin-left: 8px;
  }

  .mat-chip.info-chip .mat-icon {
    margin-right: 0;
    margin-left: 4px;
  }

  .chat-status-icon {
    margin-right: 0;
    margin-left: 6px;
  }
  .chat-sidenav,
  .mailbox-sidenav {
    border-right: none;
    border-left: 1px solid transparent;
  }

  .ql-editor.ql-blank::before {
    right: 6px;
  }
  .ql-editor {
    text-align: right;
  }
  .horizontal-menu-item {
    .horizontal-menu-icon {
      margin-right: 0;
      margin-left: 5px;
    }
  }
  .horizontal-sub-menu .horizontal-sub-menu {
    left: auto;
    right: 100%;
  }
  &.horizontal-menu {
    &.compact {
      .horizontal-menu-item {
        .horizontal-sub-menu {
          .mat-button {
            .horizontal-menu-icon {
              margin-right: 0;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  &.compact {
    .menu-icon {
      margin-left: 0;
    }
    .sub-menu .mat-button {
      padding-right: 0 !important;
    }
  }
  &.mini {
    .menu-icon {
      margin-left: 0;
    }
    .sub-menu .mat-button {
      padding-right: 0 !important;
    }
    .menu-expand-icon {
      right: auto;
      left: 0;
    }
  }

  .cal-month-view {
    .cal-day-number {
      float: left;
      margin-right: 0;
      margin-left: 15px;
    }
    .cal-day-badge {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  input.mat-input-element {
    text-align: right;
  }
  .mat-dialog-title {
    text-align: right;
  }

  .ps__rail-y {
    right: auto !important;
    left: 0 !important;
  }
  .ps__thumb-y {
    right: auto !important;
    left: 1px !important;
  }
}
