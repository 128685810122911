@import "variables";

html,
body {
  height: 100%;
}

body {
  font-family: $font-family;
  font-size: 14px;
  line-height: 1.5;
  overflow-x: hidden;
  color: $dim-grey;
}
* {
  margin: 0;
  padding: 0;
}
a {
  color: $primary;
  outline: none;
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer !important;
}

.h-100 {
  height: 100% !important;
}
.w-100 {
  width: 100% !important;
}
.h-100p {
  height: 100px;
}
.h-300p {
  height: 300px;
}
.flex-p {
  padding: $flex-distance !important;
}
.flex-p-x {
  padding: 0 $flex-distance !important;
}
.flex-p-y {
  padding: $flex-distance 0 !important;
}
.flex-m {
  margin: $flex-distance !important;
}
.flex-m-y {
  margin: $flex-distance 0 !important;
}
.flex-m-x {
  margin: 0 $flex-distance !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.5rem;
}
.py-1 {
  padding: 1rem 0;
}
.m-0 {
  margin: 0 !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.text-center {
  text-align: center;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.transition-2 {
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  transition: 0.2s;
}
.rounded {
  border-radius: 50%;
}
.divider {
  width: 100%;
  height: 1px;
  background: #ccc;
}
.toolbar-dropdown-menu {
  margin-top: 9px;
  border-radius: 0 !important;
  .mat-menu-content {
    padding: 0 !important;
  }
}
.icon-sm,
.mat-icon.icon-sm {
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  width: 18px;
  vertical-align: middle;
}
.mat-icon.icon-lg {
  font-size: 48px;
  width: 48px;
  height: 48px;
}
.white {
  color: #fff;
}
.draggable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.mat-raised-button.small-logo {
  font-size: 24px;
  font-weight: bold;
  min-width: 36px;
  padding: 0;
  background-color: #fff !important;
}

//mailbox
.mail-body {
  p {
    margin-bottom: 1rem;
  }
  ul {
    margin: 0 16px;
  }
  .ql-container.ql-snow {
    min-height: 120px;
    margin-bottom: 10px;
  }
}

//material components
.mat-card {
  overflow: hidden;
  .mat-card-header-text {
    margin: 0 !important;
  }
  &.custom-card {
    .mat-card-header {
      margin: -24px -24px 0;
    }
    .mat-card-actions {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}
.mat-list .mat-list-item,
.mat-nav-list .mat-list-item,
.mat-selection-list .mat-list-item,
.mat-menu-item {
  font-size: 14px !important;
}

.mat-drawer-container {
  height: 100%;
}
.mat-paginator-page-size-select {
  width: 60px !important;
}
.vertical-tabs.mat-tab-group {
  flex-direction: row;
  .mat-tab-labels {
    flex-direction: column;
  }
  .mat-ink-bar {
    display: none;
  }
  .mat-tab-header {
    border: none !important;
  }
  .mat-tab-body-wrapper {
    padding: 0 16px;
  }
}

.app {
  &.fixed-header {
    .mat-drawer-content {
      overflow: unset;
    }
  }
  &.horizontal-menu {
    .inner-sidenav-content {
      padding-top: $toolbar-height + $inner-sidenav-content-padding;
      min-height: calc(100% - (#{$toolbar-height} + #{$horizontal-menu-height} + #{$inner-sidenav-content-padding}* 2));
    }
    .chat-sidenav-list {
      height: calc(
        100vh - (#{$toolbar-height} + #{$horizontal-menu-height} + #{$inner-sidenav-content-padding}* 2 + 132px)
      );
    }
    .chat-content {
      height: calc(
        100vh - (#{$toolbar-height} + #{$horizontal-menu-height} + #{$inner-sidenav-content-padding}* 2 + 238px)
      );
    }
    .mailbox-sidenav-list,
    .mailbox-content {
      height: calc(
        100vh - (#{$toolbar-height} + #{$horizontal-menu-height} + #{$inner-sidenav-content-padding}* 2 + 132px)
      );
    }
    .options-icon {
      top: 126px;
    }
    &.compact {
      .horizontal-menu-item {
        .mat-button {
          font-size: 14px;
          padding-top: 8px;
          padding-bottom: 8px;
          .horizontal-menu-title {
            line-height: 20px;
          }
          .horizontal-menu-icon {
            margin: 0;
          }
        }
        .mat-button-wrapper {
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .horizontal-sub-menu {
          .mat-button {
            flex-direction: row;
            justify-content: flex-start !important;
            align-items: center;
            .horizontal-menu-icon {
              margin-right: 10px;
            }
          }
          .mat-button-wrapper {
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }
    }
    &.mini {
      .horizontal-menu-item {
        .mat-button {
          padding-top: 8px;
          padding-bottom: 8px;
          min-width: 56px;
          .horizontal-menu-title {
            display: none;
          }
          .horizontal-menu-icon {
            margin: 0;
            font-size: 36px;
            height: 36px;
            width: 36px;
          }
        }
        .horizontal-sub-menu {
          width: 68px;
          .mat-button {
            height: $horizontal-menu-height;
          }
        }
      }
    }
  }
  &.compact {
    .sidenav {
      width: 160px;
    }
    .user-block {
      .user-info-wrapper {
        margin: 0;
      }
      img {
        margin: 5px;
      }
    }
    .menu-item {
      .mat-button {
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 8px;
        .menu-title {
          line-height: 24px;
        }
      }
      .mat-button-wrapper {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .menu-icon {
      margin: 0;
      font-size: 36px;
      height: 36px;
      width: 36px;
    }
    .menu-expand-icon {
      top: 28px;
    }
    .sub-menu {
      .mat-button {
        padding-left: 0 !important;
      }
    }
  }
  &.mini {
    .sidenav {
      width: 70px;
    }
    .sidenav-menu-outer.user-block-show {
      height: calc(100vh - #{$toolbar-height + $mini-sidenav-user-block-height});
    }
    .user-block {
      img {
        width: 52px !important;
      }
      .user-info-wrapper {
        .user-info {
          p {
            display: none;
          }
        }
      }
      &.show {
        height: $mini-sidenav-user-block-height;
      }
    }
    .menu-item {
      .mat-button {
        padding-top: 8px;
        padding-bottom: 8px;
        min-width: 70px;
        .menu-title {
          line-height: 24px;
        }
      }
      .mat-button-wrapper {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .menu-title {
        display: none;
      }
    }
    .menu-icon {
      margin: 0;
      font-size: 36px;
      height: 36px;
      width: 36px;
    }
    .menu-expand-icon {
      top: 14px;
      right: 0;
    }
    .sub-menu {
      .mat-button {
        padding-left: 0 !important;
      }
    }
  }
}

//Login, Register, Errors box
.box {
  border-radius: 0;
  .box-header {
    height: 186px;
    .mat-fab {
      margin-bottom: 8px;
    }
    .error {
      font-size: 46px;
    }
  }
  .box-content {
    position: relative;
    height: $box-height;
    .box-content-inner {
      position: absolute;
      top: -$box-position;
      left: $box-position;
      right: $box-position;
      height: $box-height;
    }
    .box-content-header {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 500;
      &.server-error {
        margin-bottom: 36px;
      }
    }
    .box-text {
      margin-bottom: 10px;
      &::last-child {
        margin-bottom: 15px;
      }
    }
    .box-button {
      bottom: 16px;
      width: 40%;
      border-radius: 20px;
      overflow: hidden;
    }
  }
  &.register {
    .box-content {
      height: $register-box-height;
      .box-content-inner {
        height: $register-box-height;
      }
    }
  }
}

// default scrollbar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 8px;
  &:hover {
    background: #cccccc;
  }
  &:active {
    background: #888888;
  }
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 8px;
  &:hover {
    background: #666666;
  }
  &:active {
    background: #333333;
  }
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.mat-loading .mat-button-wrapper {
  visibility: hidden;
}

.mat-loading .mat-progress-spinner {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

.page-heading {
  font-size: 24px;
}

.mat-form-field-infix {
  border-top: 0.34375em solid transparent !important;
}

.mat-select-arrow-wrapper {
  vertical-align: bottom !important;
}

.mat-form-field-appearance-outline {
  .mat-form-field-suffix {
    top: 0.35em !important;
  }
}

.icon-warn {
  color: #f89d2e;
}

.icon-danger {
  color: #c42c00;
}

.icon-success {
  color: #4caf50;
}

.icon-grey {
  color: #b4b4b3;
}

.text-weight-bold {
  font-weight: 600;
}

// Button
.mat-button.mat-dark,
.mat-stroked-button.mat-dark {
  color: #303030;
}

.mat-raised-button.mat-dark,
.mat-flat-button.mat-dark,
.mat-fab.mat-dark,
.mat-mini-fab.mat-dark {
  color: #ffffff;
  background-color: #303030;
}

.mat-button.mat-orange,
.mat-stroked-button.mat-orange {
  color: #f89d2e;
}

.mat-raised-button.mat-orange,
.mat-flat-button.mat-orange,
.mat-fab.mat-orange,
.mat-mini-fab.mat-orange {
  color: #ffffff;
  background-color: #f89d2e;
}

.mat-button.mat-success,
.mat-stroked-button.mat-success {
  color: #4caf50;
}

.mat-raised-button.mat-success,
.mat-flat-button.mat-success,
.mat-fab.mat-success,
.mat-mini-fab.mat-success {
  color: #ffffff;
  background-color: #4caf50;
}

.mat-button.mat-danger,
.mat-stroked-button.mat-danger {
  color: #c42c00;
}

.mat-raised-button.mat-danger,
.mat-flat-button.mat-danger,
.mat-fab.mat-danger,
.mat-mini-fab.mat-danger {
  color: #ffffff;
  background-color: #c42c00;
}

.mat-button.mat-grey,
.mat-stroked-button.mat-grey {
  color: #dedede;
}

.mat-raised-button.mat-grey,
.mat-flat-button.mat-grey,
.mat-fab.mat-grey,
.mat-mini-fab.mat-grey {
  color: $primary;
  background-color: #dedede;
}

.mat-button.mat-astronaut,
.mat-stroked-button.mat-astronaut {
  color: #00465c;
}

.mat-raised-button.mat-astronaut,
.mat-flat-button.mat-astronaut,
.mat-fab.mat-astronaut,
.mat-mini-fab.mat-astronaut {
  color: #ffffff;
  background-color: #00465c;
  &.mat-button-disabled {
    color: #dedede !important;
  }
}

.mat-form-field-disabled {
  .mat-form-field-outline {
    background: #dedede;
    border-radius: 5px;
  }
}

// End Button

// Radio
.mat-radio-button.mat-success.mat-radio-checked .mat-radio-outer-circle {
  border-color: #4caf50 !important;
}

.mat-radio-button.mat-success .mat-radio-inner-circle {
  color: #4caf50 !important;
  background-color: #4caf50 !important;
}

.mat-radio-button.mat-success .mat-radio-ripple .mat-ripple-element {
  background-color: rgb(76, 175, 80, 0.26);
}

.mat-radio-button.mat-danger.mat-radio-checked .mat-radio-outer-circle {
  border-color: #c42c00 !important;
}

.mat-radio-button.mat-danger .mat-radio-inner-circle {
  color: #c42c00 !important;
  background-color: #c42c00 !important;
}

.mat-radio-button.mat-danger .mat-radio-ripple .mat-ripple-element {
  background-color: rgb(196, 44, 0, 0.26);
}
// End Radio

button {
  padding: 0 24px;
  &.mat-small {
    height: 28px;
    line-height: 28px;
  }
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.owl-dt-container {
  border-radius: 5px;
}

.text-white {
  color: #ffffff;
}

.text-black {
  color: #000000;
}
